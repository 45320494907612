<template>
    <div class="card">
        <div class="title">預訂取消趨勢</div>
        <v-chart style="width: 100%" class="chart" :option="option" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

import { ToolboxComponent, GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";

use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    LegendComponent,
    UniversalTransition,
]);

export default {
    name: "OrderCancelStatistics",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "",
    },
    computed: {
        ...mapState("dashboardStore", ["dashboardData"]),
        option() {
            if (this.dashboardData.length > 0) {
                const data = {
                    tooltip: {
                        trigger: "axis",
                    },
                    legend: {
                        itemWidth: 10,
                        itemHeight: 10,
                        textStyle: {
                            fontSize: 12,
                        },
                        orient: "vertical",
                        right: "right",
                        top: "50px",
                        data: [
                            "自動婉拒（狀態改變）",
                            "自動婉拒（未回覆）",
                            "服務商婉拒",
                            "會員取消",
                            "會員臨時取消",
                        ],
                    },
                    grid: {
                        left: "3%",
                        right: "20%",
                        top: "20px",
                        bottom: "110px",
                        containLabel: true,
                    },
                    xAxis: {
                        type: "category",
                        data: this.dashboardData[6].statistics.dates
                            ? this.dashboardData[6].statistics.dates.map(
                                  (item) => item.substring(item.length - 2)
                              )
                            : [],
                    },
                    yAxis: {
                        type: "value",
                    },
                    series: this.dashboardData[6].statistics.val
                        ? this.dashboardData[6].statistics.val.map((item) => ({
                              ...item,
                              type: "line",
                          }))
                        : [],
                };
                return data;
            }
            return {};
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin: 0px 5px;
    padding: 0 0px;
    height: 329px;
    .title {
        font-size: 14px;
        font-weight: 700;
        margin: 30px 30px 20px 30px;
    }
}
</style>
