<template>
    <div class="card">
        <div class="title">新增使用者統計</div>
        <div class="rates">
            <div v-for="(data, index) in datas" :key="index" class="rate">
                <div class="val">{{ data.value }} <span>位</span></div>
                <div class="label">{{ data.name }}</div>
            </div>
            <!-- <div class="rate">
                <div class="val">593 <span>位</span></div>
                <div class="label">會員</div>
            </div>
            <div class="rate">
                <div class="val">37 <span>位</span></div>
                <div class="label">服務商</div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("dashboardStore", ["dashboardData"]),

        datas() {
            if (this.dashboardData.length > 0) {
                return this.dashboardData[8].statistics.map((item) => {
                    if (item.name === "providers") {
                        return { ...item, name: "服務商" };
                    } else {
                        return { ...item, name: "會員" };
                    }
                });
            }
            return [];
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 5px;
    .title {
        font-size: 14px;
        font-weight: 700;
        margin: 30px 30px 20px 30px;
    }
    .rates {
        width: 100%;
        display: flex;
        .rate {
            flex: 1;
            justify-content: center;
            text-align: center;
            .val {
                font-size: 30px;
                margin-bottom: 5px;
                span {
                    font-size: 14px;
                }
            }
            .label {
                font-size: 14px;
            }
        }
    }
}
</style>
