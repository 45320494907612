<template>
    <div class="select" @click="active = !active">
        {{ labelActived }} <i class="fas fa-chevron-down ml-2" style="font-size: 12px"></i>
        <div v-if="active" class="select-down shadow-lg">
            <div v-for="item in selectOptions" :key="item.val" class="item" :class="{ focus: item.val == val }" @click.stop="choose(item.val)">
                {{ item.label }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
export default {
    name: "TimeSelect",
    computed: {
        labelActived() {
            const idx = this.selectOptions.findIndex((i) => i.val == this.val);
            return this.selectOptions[idx].label;
        },
    },
    data() {
        return {
            active: false,
            val: 0,
            selectOptions: [
                {
                    label: "過去 7 天",
                    val: 0,
                },
                {
                    label: "過去 30 天",
                    val: 1,
                },
                {
                    label: "過去 90 天",
                    val: 6,
                },
                {
                    label: "本週",
                    val: 2,
                },
                {
                    label: "前週",
                    val: 3,
                },
                {
                    label: "本月",
                    val: 4,
                },
                {
                    label: "前月",
                    val: 5,
                },
                {
                    label: "本季",
                    val: 7,
                },
                {
                    label: "前季",
                    val: 8,
                },
                {
                    label: "今年",
                    val: 9,
                },
                {
                    label: "去年",
                    val: 10,
                },
            ],
        };
    },
    methods: {
        ...mapMutations("dashboardStore", ["setDashboard"]),
        ...mapActions("apiStore", ["errorCallback"]),
        /**
         * 獲得指定時間報表數據
         * @param { type Number(數字) } time
         */
        async getDashboardDatas(time) {
            try {
                const { data } = await this.$api.DashboardApi(time);
                this.setDashboard(data.data);
            } catch (err) {
                this.errorCallback({ err });
            }
        },
        /**
         * 選擇時間
         */
        async choose(val) {
            this.val = val;
            this.active = false;
            await this.getDashboardDatas(val);
        },
    },
};
</script>

<style lang="scss" scoped>
.select {
    background: rgb(255, 204, 193);
    border-radius: 5px;
    margin: 0 5px 5px 5px;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    i {
        font-size: 12px;
        margin-left: 10px;
    }

    .select-down {
        background: #fff;
        position: absolute;
        z-index: 1000;
        width: 100%;
        left: 0%;
        top: 36px;
        border-radius: 0 0 5px 5px;
        .item {
            padding: 7px 15px;
            font-size: 12px;
            color: rgb(44, 44, 44);
            &:hover {
                background: rgba(255, 86, 50, 0.1);
            }
            &.focus {
                background: rgba(255, 86, 50, 0.1);
            }
        }
    }
}
</style>
