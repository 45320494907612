<template>
    <div class="card">
        <div class="title">訂單統計</div>
        <div class="rates">
            <div class="rate">
                <div class="val">{{ showValue("booking_success_rate") }} %</div>
                <div class="label">預訂成功率</div>
            </div>
            <div class="rate">
                <div class="val">
                    {{ showValue("active_cancellation_rate") }} %
                </div>
                <div class="label">主動取消率</div>
            </div>
            <div class="rate">
                <div class="val">
                    {{ showValue("passive_cancellation_rate") }} %
                </div>
                <div class="label">被動取消率</div>
            </div>
            <div class="rate">
                <div class="val">{{ showValue("member_cancel_rate") }} %</div>
                <div class="label">會員取消率</div>
            </div>
            <div class="rate">
                <div class="val">
                    {{ showValue("member_temporary_cancel_rate") }} %
                </div>
                <div class="label">會員臨時取消率</div>
            </div>
            <div class="rate">
                <div class="val">{{ showValue("avg_score") }} / 50</div>
                <div class="label">評分平均</div>
            </div>
            <div class="rate">
                <div class="val">{{ showValue("response_rate") }} %</div>
                <div class="label">訂單回應率</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("dashboardStore", ["dashboardData"]),
        datas() {
            if (this.dashboardData.length > 0) {
                return this.dashboardData[5].statistics;
            }
            return [];
        },
    },
    methods: {
        /**
         * 顯示數據
         * @param { type String(字串) } key
         */
        showValue(key) {
            if (!this.$isEmpty(this.datas)) {
                try {
                    this.datas.find((item) => item.name === key);
                    return this.datas.find((item) => item.name === key).value;
                } catch (err) {
                    return 0;
                }
            }
            return 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin: 10px;
    .title {
        font-size: 14px;
        font-weight: 700;
        margin: 30px 30px 20px 30px;
    }
    .rates {
        width: 100%;
        display: flex;
        .rate {
            flex: 1;
            justify-content: center;
            text-align: center;
            .val {
                font-size: 30px;
                margin-bottom: 5px;
            }
            .label {
                font-size: 14px;
            }
        }
    }
}
</style>
