<template>
    <div class="card">
        <div class="tabs">
            <div
                v-for="type in types"
                v-show="type.key === 0"
                :key="type.key"
                class="tab"
                :class="{ active: active == type.key }"
                @click="active = type.key">
                <div class="label">{{ type.name }}</div>
                <div class="val">{{ type.value | formatCurrency }}</div>
            </div>
            <div class="tab" :class="{ active: active == '2' }" @click="active = 2">
                <div class="label">預訂分潤</div>
                <div class="val">22,591</div>
                <span class="text-xs">(假數據)</span>
            </div>
            <div class="tab" :class="{ active: active == '3' }" @click="active = 3">
                <div class="label">平台服務費</div>
                <div class="val">1,175</div>
                <span class="text-xs">(假數據)</span>
            </div>
            <div class="tab" :class="{ active: active == '4' }" @click="active = 4">
                <div class="label">臨時取消手續費</div>
                <div class="val">3,800</div>
                <span class="text-xs">(假數據)</span>
            </div>
            <div class="tab" :class="{ active: active == '5' }" @click="active = 5">
                <div class="label">立即提領手續費</div>
                <div class="val">2,514</div>
                <span class="text-xs">(假數據)</span>
            </div>
        </div>
        <v-chart style="width: 100%; flex: 1" class="chart" :option="option" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

import { ToolboxComponent, GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";

use([CanvasRenderer, PieChart, TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LineChart, LegendComponent, UniversalTransition]);

export default {
    name: "PlatformStatistic",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "",
    },
    computed: {
        ...mapState("dashboardStore", ["dashboardData"]),
        datas() {
            if (this.dashboardData.length > 0) {
                // const data1 = this.option;
                // data1.xAxis.data = this.dashboardData[7].statistics[this.active].value;
            }
            return 0;
        },
        types() {
            if (this.dashboardData.length > 0) {
                const datas = this.dashboardData[7].statistics.slice(0).reverse();
                return datas.map((item, index) => ({
                    name: item.name,
                    key: index,
                    value: item.value,
                }));
            }
            return [];
        },
    },
    data() {
        return {
            active: 0,
            option: {
                tooltip: {
                    trigger: "axis",
                },
                grid: {
                    // top: "20px",
                    heigght: "100%",
                    top: "15px",
                    bottom: "20px",
                    left: "3%",
                    right: "4%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: ["21", "22", "23", "24", "25", "26", "27"],
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        name: "完成訂單數",
                        type: "line",
                        data: [5, 10, 15, 4, 3, 20, 4],
                    },
                ],
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin: 0 10px 0 5px;
    padding: 0 0px;
    height: 400px;
    display: flex;
    flex-direction: column;
    .chart {
        padding: 10px;
    }
    .tabs {
        width: 100%;
        display: flex;
        .tab {
            flex: 1;
            padding: 20px 20px;
            text-align: center;
            cursor: pointer;
            .label {
                font-size: 14px;
                font-weight: 700;
            }
            .val {
                font-size: 30px;
            }
            &.active {
                border-top: 2px solid rgb(255, 86, 50);
                background: rgba(255, 86, 50, 0.1);
            }
            &:hover {
                background: rgba(255, 86, 50, 0.1);
            }
        }
    }
}
</style>
