<template>
    <div class="card">
        <v-chart v-if="true" style="width: 100%" class="chart" :option="option" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

import { ToolboxComponent, GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";

use([CanvasRenderer, PieChart, TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LineChart, LegendComponent, UniversalTransition]);

export default {
    name: "CityStatistics",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "",
    },
    computed: {
        ...mapState("dashboardStore", ["dashboardData"]),
        option() {
            if (this.dashboardData.length > 0) {
                const data = {
                    title: {
                        textStyle: {
                            fontSize: "14px",
                        },
                        text: "會面城市佔比",
                        left: "20px",
                        top: "10px",
                    },
                    color: ["rgb(211,126,107)", "rgba(246,178,107)", "rgba(255,217,102)", "rgb(147,196,125)", "rgb(109,158,235)", "rgb(142,124,195)"],
                    tooltip: {
                        trigger: "item",
                        formatter: "{a} <br/>{b} : {c} ({d}%)",
                    },
                    legend: {
                        orient: "vertical",
                        left: "20px",
                        top: "50px",
                        itemWidth: 10,
                        itemHeight: 10,
                        data: ["台北", "桃園", "台中", "雲嘉南", "高雄", "花東"],
                        textStyle: {
                            fontSize: 12,
                        },
                    },
                    series: [
                        {
                            name: "活動項目佔比",
                            type: "pie",
                            radius: "70%",
                            center: ["70%", "50%"],
                            label: {
                                show: false,
                            },
                            data: this.dashboardData[4].statistics,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                };
                return data;
            }
            return {};
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin: 5px 5px;
    margin-bottom: 0;
}
</style>
