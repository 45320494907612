<template>
    <div class="card">
        <v-chart style="width: 100%" class="chart" :option="option" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

import { ToolboxComponent, GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";

use([CanvasRenderer, PieChart, TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LineChart, LegendComponent, UniversalTransition]);

export default {
    name: "CustomerSeverityStatistics",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "",
    },
    computed: {
        ...mapState("dashboardStore", ["dashboardData"]),
        option() {
            if (this.dashboardData.length > 0) {
                const data = {
                    title: {
                        textStyle: {
                            fontSize: "14px",
                        },
                        text: "客訴單緊急程度佔比",
                        left: "20px",
                        top: "25px",
                    },
                    color: ["rgba(255,87,51,.3)", "rgba(255,87,51,.6)", "rgba(255,87,51,1)"],
                    tooltip: {
                        trigger: "item",
                        formatter: "{a} <br/>{b} : {c} ({d}%)",
                    },
                    legend: {
                        orient: "vertical",
                        left: "20px",
                        top: "55px",
                        itemWidth: 10,
                        itemHeight: 10,
                        data: this.dashboardData[11].statistics.map((item) => item.name),
                        textStyle: {
                            fontSize: 12,
                        },
                    },
                    series: [
                        {
                            name: "客訴單緊急程度佔比",
                            type: "pie",
                            radius: "70%",
                            center: ["70%", "50%"],
                            label: {
                                show: false,
                            },
                            data: this.dashboardData[11].statistics,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                };
                return data;
            }
            return {};
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin: 5px;
    padding: 0 0px;
    height: 164px;
}
</style>
