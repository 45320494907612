import { render, staticRenderFns } from "./CustomerStatistics.vue?vue&type=template&id=cc2d5464&scoped=true"
import script from "./CustomerStatistics.vue?vue&type=script&lang=js"
export * from "./CustomerStatistics.vue?vue&type=script&lang=js"
import style0 from "./CustomerStatistics.vue?vue&type=style&index=0&id=cc2d5464&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc2d5464",
  null
  
)

export default component.exports