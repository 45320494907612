<template>
    <div class="card">
        <div class="title">金額統計</div>
        <div class="rates">
            <div v-for="(data, index) in datas" :key="index" class="rate">
                <div class="val">{{ data.value | formatCurrency }}</div>
                <div class="label">{{ data.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("dashboardStore", ["dashboardData"]),

        datas() {
            if (this.dashboardData.length > 0) {
                return this.dashboardData[7].statistics.filter((item) => item.name !== "平台總收益");
            }
            return [];
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    .title {
        font-size: 14px;
        font-weight: 700;
        margin: 30px 30px 20px 30px;
    }
    .rates {
        width: 100%;
        display: flex;
        .rate {
            flex: 1;
            justify-content: center;
            text-align: center;
            .val {
                font-size: 30px;
                margin-bottom: 5px;
            }
            .label {
                font-size: 14px;
            }
        }
    }
}
</style>
