<template>
    <div class="card">
        <div class="tabs">
            <div
                class="tab"
                :class="{ active: active == '1' }"
                @click="active = 1"
            >
                <div class="label">完成訂單數</div>
                <div class="val">{{ orderTotal }}</div>
            </div>
            <div
                class="tab"
                :class="{ active: active == '2' }"
                @click="active = 2"
            >
                <div class="label">完成訂單總金額</div>
                <div class="val">{{ orderMoney }}</div>
            </div>
        </div>
        <v-chart
            style="width: 100%; flex: 1"
            class="chart"
            :option="active == '1' ? orderCountVal : orderMoneyVal"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

import { ToolboxComponent, GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";

use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LineChart,
    LegendComponent,
    UniversalTransition,
]);

// 計算 陣列裡 數字總合方法
import { sum as _sum } from "lodash/math";

export default {
    name: "OrderStistics",
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "",
    },
    computed: {
        ...mapState("dashboardStore", ["dashboardData"]),
        // 訂單總數
        orderTotal() {
            if (this.dashboardData.length > 0) {
                const count = _sum(this.dashboardData[0].statistics.val);
                return count;
            }
            return 0;
        },
        // 訂單總額
        orderMoney() {
            if (this.dashboardData.length > 0) {
                const count = _sum(this.dashboardData[1].statistics.val);
                return count;
            }
            return 0;
        },
        // 訂單數據
        orderCountVal() {
            if (this.dashboardData.length > 0) {
                const data = {
                    tooltip: {
                        trigger: "axis",
                    },
                    grid: {
                        // top: "20px",
                        heigght: "100%",
                        top: "15px",
                        bottom: "20px",
                        left: "3%",
                        right: "4%",
                        containLabel: true,
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: this.dashboardData[0].statistics.dates
                            ? this.dashboardData[0].statistics.dates.map(
                                  (item) => item.substring(item.length - 2)
                              )
                            : [],
                    },
                    yAxis: {
                        type: "value",
                    },
                    series: [
                        {
                            name: "完成訂單數",
                            type: "line",
                            data: this.dashboardData[0].statistics.val,
                        },
                    ],
                };
                return data;
            }
            return {};
        },
        // 訂單總金額數據
        orderMoneyVal() {
            if (this.dashboardData.length > 0) {
                const data = {
                    tooltip: {
                        trigger: "axis",
                    },
                    grid: {
                        // top: "20px",
                        heigght: "100%",
                        top: "15px",
                        bottom: "20px",
                        left: "3%",
                        right: "4%",
                        containLabel: true,
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: this.dashboardData[1].statistics.dates.map(
                            (item) => item.substring(item.length - 2)
                        ),
                    },
                    yAxis: {
                        type: "value",
                    },
                    series: [
                        {
                            name: "完成訂單總金額",
                            type: "line",
                            data: this.dashboardData[1].statistics.val,
                        },
                    ],
                };
                return data;
            }
            return {};
        },
    },
    data() {
        return {
            active: "1",
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-radius: 5px;
    margin: 0 5px;
    padding: 0 0px;
    height: 340px;
    display: flex;
    flex-direction: column;
    .chart {
        padding: 10px;
    }
    .tabs {
        width: 100%;
        display: flex;
        .tab {
            padding: 15px 20px;
            cursor: pointer;
            .label {
                font-size: 14px;
                font-weight: 700;
            }
            .val {
                font-size: 30px;
            }
            &.active {
                border-top: 2px solid rgb(255, 86, 50);
                background: rgba(255, 86, 50, 0.1);
            }
            &:hover {
                background: rgba(255, 86, 50, 0.1);
            }
        }
    }
}
</style>
